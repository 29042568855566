import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../PageBuilder/Field';
import BlockBuilder, { BlockContainer } from '../../PageBuilder/BlockBuilder';

import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer';

import css from './SectionFindMyMates.module.css';
import { H1 } from '../../PageBuilder/Primitives/Heading';
import { P } from '../../PageBuilder/Primitives/P';
import { NamedLink } from '../../../components';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFindMyMates = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  console.log('blocks', blocks);
  console.log('fieldOptions', fieldOptions);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      <div
        className={classNames(defaultClasses.blockContainer, css.featuresMain, {
          [css.noSidePaddings]: isInsideContainer,
        })}
      >
        <div className={css.blockContainer}>
          <div className={css.text}>
            <H1>
              <FormattedMessage id="SectionFindMyMates.title" />
            </H1>
            <P>
              <FormattedMessage id="SectionFindMyMates.description" />
            </P>
          </div>

          <div className={css.links}>
            <NamedLink
              className={css.link}
              name="SearchPage"
              to={{ search: 'pub_listingType=media_partner' }}
            >
              <FormattedMessage id="SectionFindMyMates.links.media_partners" />
            </NamedLink>

            <NamedLink
              className={css.link}
              name="SearchPage"
              to={{ search: 'pub_listingType=influencer' }}
            >
              <FormattedMessage id="SectionFindMyMates.links.influencers" />
            </NamedLink>

            <NamedLink
              className={css.link}
              name="SearchPage"
              to={{ search: 'pub_listingType=event' }}
            >
              <FormattedMessage id="SectionFindMyMates.links.events" />
            </NamedLink>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFindMyMates.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFindMyMates.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFindMyMates;
